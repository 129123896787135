/* Modals */

.modal{
  .modal-dialog{
    margin-top: 100px;
    .modal-content{
      .modal-header{
        padding: $modal-header-padding-y $modal-header-padding-x;
        .close{
          span{
            font-size: 20px;
            font-weight: 400;
            color: #6a6a6a;
          }
        }
      }
      .modal-body{
        padding: $modal-body-padding-y $modal-body-padding-x;
      }
      .modal-footer{
        padding: $modal-footer-padding-y $modal-footer-padding-x;
      }
    }
  }
}